import { TFunction } from 'i18next';
import Image from 'next/image';
import Link from 'next/link';
import { useParams } from 'next/navigation';
import React from 'react';
import { BiMicrophone, BiPlay, BiRefresh, BiVolumeFull } from 'react-icons/bi';

const CompareSection = ({ t }: { t: TFunction<'translation', undefined> }) => {
  const params = useParams();
  return (
    <section>
      <div className="tw-w-full md:tw-h-[968px] md:tw-px-20 tw-py-[84px] tw-bg-gray-800 tw-flex-col tw-justify-start tw-items-center tw-gap-[52px] tw-inline-flex">
        <h2 className="tw-text-center tw-text-gray-100 tw-text-4xl tw-font-bold tw-font-['Lexend'] tw-leading-10">
          {t('Advantage/Compare/title')}
        </h2>
        <div className="tw-justify-center tw-items-center lg:tw-gap-[84px] md:tw-gap-[22px] md:tw-inline-flex w">
          <div className="tw-w-80 tw-flex-col tw-justify-center tw-items-center tw-gap-6 tw-inline-flex">
            <div className="tw-h-[576px] tw-w-full tw-shadow tw-flex-col tw-justify-start tw-items-end tw-gap-3 tw-flex">
              <div className="tw-h-[576px] tw-w-full tw-flex-col tw-justify-start tw-items-start tw-flex">
                <div className="tw-self-stretch tw-pl-4 tw-pr-3 tw-py-3 tw-bg-gray-50 tw-rounded-tl-3xl tw-rounded-tr-3xl tw-shadow tw-justify-between tw-items-center tw-inline-flex">
                  <div className="tw-justify-start tw-items-center tw-gap-3 tw-flex">
                    <div className="tw-w-10 tw-h-10  tw-rounded-full">
                      <Image
                        src="https://cdn.1cdn.app/application/LOUISEBOT/23091707385795_Logo_colorful.svg"
                        alt="logo"
                        width={40}
                        height={40}
                      />
                    </div>
                    <div className="tw-flex-col tw-justify-center tw-items-start tw-inline-flex">
                      <p className="tw-text-gray-700 tw-text-lg tw-font-semibold tw-font-['Lexend'] tw-leading-7">
                        Louise
                      </p>
                      <span className="tw-text-blue-700 tw-text-xs tw-font-normal tw-font-['Lexend'] tw-leading-none">
                        Online
                      </span>
                    </div>
                  </div>
                  <div className="tw-justify-end tw-items-center tw-gap-3 tw-flex">
                    <div className="tw-p-2 tw-bg-white tw-rounded-[99px] tw-shadow tw-justify-center tw-items-center tw-gap-1 tw-flex">
                      <BiRefresh className="tw-w-6 tw-h-6 tw-relative" />
                    </div>
                  </div>
                </div>
                <div className="tw-h-[424px] tw-px-4 tw-pt-2 tw-pb-3 tw-w-full tw-bg-gray-50 tw-flex-col tw-justify-end tw-items-center tw-gap-2 tw-flex">
                  <div className="tw-self-stretch tw-h-11 tw-flex-col tw-justify-start tw-items-end tw-gap-2 tw-flex">
                    <div className="tw-px-4 tw-py-3 tw-bg-white tw-rounded-2xl tw-shadow tw-justify-center tw-items-center tw-gap-2.5 tw-inline-flex">
                      <p className="tw-text-gray-700 tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight">
                        I want to know how this works
                      </p>
                    </div>
                  </div>
                  <div className="tw-self-stretch tw-h-[200px] tw-flex-col tw-justify-start tw-items-start tw-gap-2 tw-flex">
                    <div className="tw-px-4 tw-py-3 tw-bg-blue-600 tw-rounded-2xl tw-shadow tw-justify-center tw-items-center tw-gap-2.5 tw-inline-flex">
                      <p className="tw-w-[220px] tw-text-white tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight">
                        I can tell you how this works. You can make chatbots based on flows (Conditional Scenario) or
                        give it to AI.
                      </p>
                    </div>
                    <div className="tw-w-[252px] tw-px-4 tw-py-2.5 tw-bg-white tw-rounded-xl tw-shadow tw-border tw-border-blue-500 tw-justify-center tw-items-center tw-gap-2.5 tw-inline-flex">
                      <span className="tw-text-blue-700 tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight">
                        Tell me about Scenario Builder
                      </span>
                    </div>
                    <div className="tw-w-[252px] tw-px-4 tw-py-2.5 tw-bg-white tw-rounded-xl tw-shadow tw-border tw-border-blue-500 tw-justify-center tw-items-center tw-gap-2.5 tw-inline-flex">
                      <span className="tw-text-blue-700 tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight">
                        Tell me about AI Chatbots
                      </span>
                    </div>
                  </div>
                </div>
                <div className="tw-self-stretch tw-px-4 tw-pt-1 tw-bg-gray-50 tw-justify-start tw-items-start tw-gap-3 tw-inline-flex">
                  <div className="tw-grow tw-shrink tw-basis-0 tw-h-12 tw-pl-4 tw-pr-2 tw-py-2 tw-bg-white tw-rounded-2xl tw-shadow tw-border tw-border-gray-100 tw-justify-between tw-items-center tw-flex">
                    <p className="tw-text-[#535865] tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight">
                      Message...
                    </p>
                    <div className="tw-p-1 tw-bg-white tw-rounded-[99px] tw-justify-start tw-items-start tw-gap-2.5 tw-flex">
                      <BiMicrophone className="tw-w-6 tw-h-6 tw-relative" />
                    </div>
                  </div>
                </div>
                <div className="tw-self-stretch tw-py-2 tw-bg-gray-50 tw-rounded-bl-3xl tw-rounded-br-3xl tw-justify-center tw-items-center tw-gap-[5px] tw-inline-flex">
                  <div className="tw-h-4 tw-relative">
                    <div className="tw-w-4 tw-h-4 tw-left-0 tw-top-0 tw-absolute tw-flex-col tw-justify-start tw-items-start tw-inline-flex" />
                    <div className="tw-w-[48.35px] tw-h-2 tw-left-[18.40px] tw-top-[4px] tw-absolute"></div>
                  </div>
                </div>
              </div>
            </div>
            <h2 className="tw-text-center tw-text-gray-100 tw-text-2xl tw-font-semibold tw-font-['Lexend'] tw-uppercase tw-leading-loose tw-tracking-wide">
              Text Chatbot
            </h2>
          </div>
          <div className="tw-text-center tw-text-gray-100 tw-text-7xl tw-font-bold tw-font-['Lexend'] tw-leading-[72px]">
            vs.
          </div>
          <div className="tw-w-80 tw-flex-col tw-justify-center tw-items-center tw-gap-6 tw-inline-flex">
            <div className="tw-w-80 tw-h-[574px] tw-relative tw-bg-gray-50 tw-rounded-3xl">
              <Image
                className="tw-w-80 tw-h-[574px] tw-left-0 tw-top-0 tw-absolute tw-rounded-3xl tw-border tw-border-black"
                src="https://cdn.1cdn.app/application/LOUISEBOT/24070709372974_Video_Pop-up.png"
                alt="Video Pop-up Comparison"
                width={0}
                height={0}
                sizes="25vw"
              />
              <div className="tw-w-[66.75px] tw-h-4 tw-left-[127px] tw-top-[550px] tw-absolute">
                <div className="tw-w-4 tw-h-4 tw-left-0 tw-top-0 tw-absolute tw-flex-col tw-justify-start tw-items-start tw-inline-flex" />
                <div className="tw-w-[48.35px] tw-h-2 tw-left-[18.40px] tw-top-[4px] tw-absolute"></div>
              </div>
              <div className="tw-w-8 tw-h-8 tw-p-1 tw-left-[144px] tw-top-[520px] tw-absolute tw-bg-black tw-opacity-20 tw-rounded-[99px] tw-backdrop-blur-md tw-justify-center tw-items-center tw-gap-1 tw-inline-flex">
                <div className="tw-w-6 tw-h-6 tw-relative" />
              </div>
              <div className="tw-w-[90%] tw-right-[16px] tw-h-[168px] tw-left-[16px] tw-top-[328px] tw-absolute tw-flex-col tw-justify-start tw-items-start tw-gap-3 tw-inline-flex">
                <div className="tw-w-72 tw-h-12 tw-px-6 tw-py-3 tw-bg-blue-700 tw-rounded-2xl tw-border tw-border-slate-600 tw-backdrop-blur-md tw-justify-center tw-items-center tw-gap-2.5 tw-inline-flex">
                  <p className="tw-text-white tw-text-base tw-font-normal tw-font-['Lexend'] tw-leading-normal">
                    Get started
                  </p>
                </div>
                <div className="tw-w-72 tw-h-12 tw-px-6 tw-py-3 tw-bg-black/tw-opacity-50 tw-rounded-2xl tw-border tw-border-slate-400 tw-backdrop-blur-md tw-justify-center tw-items-center tw-gap-2.5 tw-inline-flex">
                  <p className="tw-text-white tw-text-base tw-font-normal tw-font-['Lexend'] tw-leading-normal">
                    Send us an email
                  </p>
                </div>
                <div className="tw-w-72 tw-h-12 tw-px-6 tw-py-3 tw-bg-black/tw-opacity-50 tw-rounded-2xl tw-border tw-border-slate-400 tw-backdrop-blur-md tw-justify-center tw-items-center tw-gap-2.5 tw-inline-flex">
                  <p className="tw-text-white tw-text-base tw-font-normal tw-font-['Lexend'] tw-leading-normal">
                    Have a tour
                  </p>
                </div>
              </div>
              <div className="tw-w-[296px] tw-h-8 tw-left-[12px] tw-top-[12px] tw-absolute tw-justify-start tw-items-center tw-gap-3 tw-inline-flex">
                <div className="tw-p-1 tw-bg-black tw-opacity-20 tw-rounded-[99px] tw-backdrop-blur-md tw-justify-center tw-items-center tw-gap-1 tw-flex">
                  <BiPlay className="tw-w-6 tw-h-6 tw-relative tw-text-white tw-opacity-100" />
                </div>
                <div className="tw-w-52 tw-h-5 tw-relative">
                  <div className="tw-w-52 tw-h-2 tw-left-0 tw-top-[6px] tw-absolute tw-bg-black tw-opacity-25 tw-rounded-[99px] tw-backdrop-blur-md" />
                  <div className="tw-w-[114px] tw-h-2 tw-left-0 tw-top-[6px] tw-absolute tw-bg-blue-700 tw-rounded-[99px]" />
                  <div className="tw-w-5 tw-h-5 tw-left-[104px] tw-top-[-0px] tw-absolute">
                    <div className="tw-w-5 tw-h-5 tw-left-0 tw-top-0 tw-absolute tw-bg-gray-50 tw-rounded-full tw-shadow" />
                    <div className="tw-w-1.5 tw-h-1.5 tw-left-[7px] tw-top-[7px] tw-absolute tw-bg-blue-700 tw-rounded-full" />
                  </div>
                </div>
                <div className="tw-p-1 tw-bg-black tw-opacity-20 tw-rounded-[99px] tw-backdrop-blur-md tw-justify-center tw-items-center tw-gap-1 tw-flex">
                  <div className="tw-w-6 tw-h-6 tw-relative">
                    <div className="tw-left-[4px] tw-top-[4px] tw-absolute">
                      <BiVolumeFull className="tw-w-4 tw-h-[15.79px] tw-text-white" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h2 className="tw-text-center tw-text-gray-100 tw-text-2xl tw-font-semibold tw-font-['Lexend'] tw-uppercase tw-leading-loose tw-tracking-wide">
              Video Pop-ups
            </h2>
          </div>
        </div>
        <div className="tw-pointer-event tw-text-center tw-text-blue-400 tw-text-base tw-font-normal tw-font-['Lexend'] tw-leading-normal">
          <Link href={`/${params.lang}/compare-chatbot-video`} target="_blank">
            {t('Advantage/Compare/l-01')}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default CompareSection;
