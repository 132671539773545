import { TFunction } from 'i18next';
import Image from 'next/image';
import React from 'react';
import { Container } from 'react-bootstrap';

const Section10 = ({ t }: { t: TFunction<'translation', undefined> }) => {
  const data = [
    {
      file: '24010911055891_Dropbox.svg',
      cdnPath: 'https://cdn.1cdn.app/application/LOUISEBOT/24010911055891_Dropbox.svg',
    },
    {
      file: '24010911055872_Freshdesk.svg',
      cdnPath: 'https://cdn.1cdn.app/application/LOUISEBOT/24010911055872_Freshdesk.svg',
    },
    {
      file: '24010911055862_Helpdesk.svg',
      cdnPath: 'https://cdn.1cdn.app/application/LOUISEBOT/24010911055862_Helpdesk.svg',
    },
    {
      file: '24010911055848_Magento.svg',
      cdnPath: 'https://cdn.1cdn.app/application/LOUISEBOT/24010911055848_Magento.svg',
    },
    {
      file: '24010911055898_Livechat.svg',
      cdnPath: 'https://cdn.1cdn.app/application/LOUISEBOT/24010911055898_Livechat.svg',
    },
    {
      file: '24010911055815_Mailchimp.svg',
      cdnPath: 'https://cdn.1cdn.app/application/LOUISEBOT/24010911055815_Mailchimp.svg',
    },
    {
      file: '2401091105581_Messenger.svg',
      cdnPath: 'https://cdn.1cdn.app/application/LOUISEBOT/2401091105581_Messenger.svg',
    },
    {
      file: '24010911065113_Salesforce.svg',
      cdnPath: 'https://cdn.1cdn.app/application/LOUISEBOT/24010911065113_Salesforce.svg',
    },
    {
      file: '24010911065145_Shopify.svg',
      cdnPath: 'https://cdn.1cdn.app/application/LOUISEBOT/24010911065145_Shopify.svg',
    },
    {
      file: '24010911065123_Slack.svg',
      cdnPath: 'https://cdn.1cdn.app/application/LOUISEBOT/24010911065123_Slack.svg',
    },
    {
      file: '24010911065115_Twilio.svg',
      cdnPath: 'https://cdn.1cdn.app/application/LOUISEBOT/24010911065115_Twilio.svg',
    },
    {
      file: '2401091106517_Wordpress.svg',
      cdnPath: 'https://cdn.1cdn.app/application/LOUISEBOT/24042206483316_Wordpress.svg',
    },
    {
      file: '24010911065141_Zapier.svg',
      cdnPath: 'https://cdn.1cdn.app/application/LOUISEBOT/24010911065141_Zapier.svg',
    },
    {
      file: '24010911065194_Zendesk.svg',
      cdnPath: 'https://cdn.1cdn.app/application/LOUISEBOT/24042206483387_Zendesk.svg',
    },
  ];

  return (
    <section className="sec-10">
      <Container fluid>
        <div className="wrapper">
          <h2>{t('Home/Section10/key1')}</h2>

          <div className="lg:tw-w-[880px] md:tw-mx-auto tw-flex tw-flex-row tw-flex-wrap md:tw-gap-12 tw-gap-6 tw-justify-center tw-align-middle tw-mb-8 tw-mt-4">
            {data.map((item, index) => (
              <div className="swiper-slide" key={index}>
                <Image
                  alt={item.file}
                  src={item.cdnPath}
                  width={0}
                  height={0}
                  sizes="100vw"
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
            ))}
          </div>

          <h3 className="text-center">{t('Home/Section10/key2')}</h3>
        </div>
      </Container>
    </section>
  );
};

export default Section10;
