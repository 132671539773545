import { useWindowSize } from '@/hooks/useWindowSize';
import { TFunction } from 'i18next';
import Image from 'next/image';
import React from 'react';

type Props = {};

const Section11 = ({ t, params }: { t: TFunction<'translation', undefined>; params: any }) => {
  const [width, height] = useWindowSize();

  return (
    <div className="tw-grid tw-grid-cols-12 tw-gap-6 tw-items-start xl:tw-min-h-[650px] tw-h-full tw-bg-gray-800 tw-py-8">
      <div className="tw-col-span-3 tw-relative tw-h-full md:tw-flex md:tw-flex-col tw-hidden">
        <Image
          src="https://cdn.1cdn.app/application/LOUISEBOT/24091507401812_24071407522722_Group_2610662.webp"
          alt="Video chatbot"
          style={{
            width: '100%',
            height: 'auto',
          }}
          width={378}
          height={654}
          className="tw-mt-auto tw-relative -tw-bottom-[70px]"
          sizes="100vw"
          placeholder="empty"
          priority={width >= 768 ? true : false}
        />
      </div>
      <div className="md:tw-col-span-6 tw-col-span-12 tw-h-full">
        <div className="tw-self-stretch tw-h-full tw-flex tw-flex-col tw-justify-around tw-items-center tw-gap-6">
          <div className="tw-self-stretch tw-flex-col tw-justify-start tw-items-center tw-gap-6 tw-flex">
            <div className="tw-pl-1 tw-pr-4 tw-py-1 tw-bg-gray-900 tw-rounded-[14px] tw-justify-start tw-items-center tw-gap-4 tw-inline-flex">
              <div className="tw-px-3 tw-py-0.5 tw-bg-[#144CD2] tw-rounded-[10px] tw-justify-start tw-items-center tw-flex">
                <span className="tw-text-white tw-text-sm tw-font-semibold tw-font-['Lexend'] tw-leading-tight ">
                  {t('Home/Section11/key1')}
                </span>
              </div>
              <div className="tw-justify-start tw-items-center tw-gap-2 tw-flex tw-h-7">
                <span className="tw-text-white  tw-font-normal tw-font-['Lexend'] tw-leading-normal tw-text-[12px] md:tw-text-base ">
                  {t('Home/Section11/key2')}
                </span>
              </div>
            </div>
            <div className="tw-self-stretch tw-flex-col tw-justify-center tw-items-center tw-gap-5 tw-flex">
              <h1 className="xl:tw-w-[624px] tw-w-full tw-text-center md:tw-text-4xl tw-text-lg">
                <span className="tw-text-gray-50 tw-text-5xl tw-font-bold tw-font-['Lexend'] tw-leading-[48px]">
                  {t('Home/Section11/key3-1')}
                </span>{' '}
                <span className="tw-text-yellow-400 tw-text-5xl tw-font-bold tw-font-['Lexend'] tw-leading-[48px]">
                  {t('Home/Section11/key3-2')}
                </span>{' '}
                <br />
                <span className="tw-text-gray-50 tw-text-5xl tw-font-bold tw-font-['Lexend'] tw-leading-[48px]">
                  {t('Home/Section11/key3-3')}
                </span>{' '}
                <span className="tw-text-green-500 tw-text-5xl tw-font-bold tw-font-['Lexend'] tw-leading-[48px]">
                  {t('Home/Section11/key3-4')}
                </span>
                <br />
                <span className="tw-text-gray-50 tw-text-5xl tw-font-bold tw-font-['Lexend'] tw-leading-[48px]">
                  {t('Home/Section11/key3-5')}
                </span>
              </h1>
            </div>
          </div>

          <div className="tw-px-6 tw-py-[9px] tw-bg-blue-600 tw-rounded-[99px] tw-shadow tw-justify-center tw-items-center tw-inline-flex">
            <a
              href={`${process.env.NEXT_PUBLIC_DASHBOARD_BASE_URL as string}/${
                params?.lang ? ((params.lang as string).startsWith('en') ? 'en-US' : 'fr-FR') : 'en-US'
              }/register`}
              className="tw-text-white tw-text-base tw-font-semibold tw-font-['Figtree'] tw-leading-normal "
            >
              {t('Home/Section11/key4')}
            </a>
          </div>

          <div className="tw-self-stretch tw-flex-col tw-justify-start tw-items-center tw-gap-6 tw-flex">
            <div className="tw-justify-start tw-items-center tw-gap-2 tw-inline-flex">
              <div className="tw-w-[66px] tw-text-center tw-text-green-500 tw-text-2xl tw-font-extrabold tw-font-['Sofia Sans'] tw-leading-[19px]">
                40%
              </div>
              <span className="tw-text-gray-50 tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight">
                {t('Home/Section11/key5')}
              </span>
            </div>
            <div className="tw-justify-start tw-items-center tw-gap-2 tw-inline-flex">
              <div className="tw-w-[66px] tw-text-center tw-text-pink-500 tw-text-2xl tw-font-extrabold tw-font-['Sofia Sans'] tw-leading-[19px]">
                24/7
              </div>
              <span className="tw-text-gray-50 tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight">
                {t('Home/Section11/key6')}
              </span>
            </div>
            <div className="tw-justify-start tw-items-center tw-gap-2 tw-inline-flex">
              <div className="tw-text-center tw-text-blue-500 tw-text-2xl tw-font-extrabold tw-font-['Sofia Sans'] tw-leading-[19px]">
                🚀
              </div>
              <span className="tw-text-gray-50 tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight">
                {t('Home/Section11/key7')}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="tw-col-span-3 tw-relative tw-h-full md:tw-flex md:tw-flex-col tw-hidden">
        <Image
          src="https://cdn.1cdn.app/application/LOUISEBOT/24091507401825_24071510273430_Group_2610663.webp"
          alt="VIDEO POP-UPS"
          style={{
            width: '100%',
            height: 'auto',
          }}
          width={378}
          height={654}
          className="tw-mt-auto tw-relative -tw-bottom-[70px]"
          sizes="100vw"
          placeholder="empty"
          priority={width >= 768 ? true : false}
        />
      </div>
    </div>
  );
};

export default Section11;
